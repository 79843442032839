import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Logo from "../../images/logo.svg"
import {Link} from "gatsby";

export default function Footer(props) {
  const dateNow = new Date();
  const year = dateNow && dateNow.getFullYear();
  return (
    <footer>
      <Container>
        <Row>
          <Col xs={12} lg={5}>
            <a href="/">
              <img src={Logo} alt="logo" className="footer-logo mb-5" />
            </a>
          </Col>
          <Col xs={12} lg={7} className="ml-auto">
            <Row>
              <Col xs={12} lg={4}>
                <div className="title">
                  {"Company"}
                </div>
                <Link to="/?section=tips">
                  <Button variant="footer">
                    {"Features"}
                  </Button>
                </Link>
                <Link to="/?section=join">
                  <Button variant="footer">
                    {"Join"}
                  </Button>
                </Link>
                <Link to="/login">
                  <Button variant="footer">
                    {"Sign-in"}
                  </Button>
                </Link>
              </Col>
              <Col xs={12} lg={6}>
                <div className="title">
                  {"Support"}
                </div>
                <Button variant="footer" onClick={() => window.groove.widget.toggle()}>
                  {"Contact Us"}
                </Button>
                
                <a target="_blank" href={"mailto:helpdesk@speedy.tel"}>
                  <Button variant="footer">
                    {"Email: helpdesk@speedy.tel"}
                  </Button>
                </a>
                <a target="_blank" href={"tel:+61370680901"}>
                  <Button variant="footer">
                    {"Tel: 03-7068-0901"}
                  </Button>
                </a>
                <a target="_blank" href="/pdfs/SpeedyTel-Terms-And-Conditions.pdf">
                  <Button variant="footer">
                    {"Terms & Conditions"}
                  </Button>
                </a>
                <a target="_blank" href="/pdfs/SpeedyTel-Critical-Information-Summary.pdf">
                  <Button variant="footer">
                    {"Critical Information Summary"}
                  </Button>
                </a>
                <a target="_blank" href="/esafety">
                  <Button variant="footer">
                    {"eSafety"}
                  </Button>
                </a>
                <a target="_blank" href="https://cdn1.vostro.cloud/provider/speedytel/content/complaints-handling-policy-speedytel.pdf">
                  <Button variant="footer">
                    {"Complaints Handling Policy"}
                  </Button>
                </a>
                <a target="_blank" href="https://cdn1.vostro.cloud/provider/speedytel/content/payment-assistance-summary-speedytel.pdf">
                  <Button variant="footer">
                    {"Payments Assistance"}
                  </Button>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="text-center mt-5 d-block copyright">
          {`Copyright © ${year || "202x"} SpeedyTel`}
        </div>
      </Container>
    </footer>
  );
}