import { Link } from "gatsby";
import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logoWhite from "../../images/logo.svg";

export default function Header() {
  return (
    <header className="header">
      <Container>
        <Navbar bg="white" variant="light" expand="lg">
          <Navbar.Brand href="/">
            <img src={logoWhite} alt="logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Link className="nav-link" to="/?section=about">About</Link>
              <Link className="nav-link" to="/?section=plans">Plans</Link>
              <Link className="nav-link" to="/?section=why">Why SpeedyTel?</Link>
              <a className="nav-link" href="javascript:$zopim.livechat.window.show()">Help</a>
              <Link className="nav-link btn-skyblue btn-login-nav" to="/login">Sign In</Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
}
